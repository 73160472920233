import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Text, Html } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import ReefBase from '../assets/reefb.stl';
import ReefTree from '../assets/reeft.stl';

const offsetfromcenterb= 69
const offsetfromcenterbv = -70
const offsetfromcentert= 53
const offsetfromcentertv = -63

function UpperLevelCylinder({ color, position, rotation }) {
    const points = [];
    const height = 24;
    const disks = 4;
  
    points.push(new THREE.Vector2(0, 0));
    for (let i = 0; i <= disks; i++) {
      const y = (i / disks) * height;
      const radius = 80 + Math.sin((i / disks) * Math.PI) * 10;
      points.push(new THREE.Vector2(radius, y));
    }
    points.push(new THREE.Vector2(0, height));
  
    return (
      <mesh position={position} rotation={rotation}>
        <latheGeometry args={[points, 64]} />
        <meshStandardMaterial
                    color={color}
                    transparent={true}
                    opacity={0.3}
                    />
      </mesh>
    );
  }

function STLModel({ url, position, rotation , color}) {
    const meshRef = useRef();
  
    useEffect(() => {
      const loader = new STLLoader();
      loader.load(url, (geometry) => {
        geometry.computeVertexNormals();
        meshRef.current.geometry = geometry;
      });
    }, [url]);
  
    return (
        <mesh ref={meshRef} position={position} rotation={rotation} castShadow receiveShadow>
            <meshStandardMaterial color={color}  flatShading="false"/>
        </mesh>
    );
  }

function Reef(props) {
    const [colors, setColors] = useState(["rgb(255,0,0)", "rgb(255,0,0)", "rgb(255,0,0)", "rgb(255,0,0)"]);

    useEffect(() => {
        if (props.stats) {
            setColors([
            `rgb(${Math.round(props.stats.percent.coral1Prob>.5?props.stats.percent.coral1Prob*255*2:255)},${Math.round(props.stats.percent.coral1Prob<.5?props.stats.percent.coral1Prob*255*2:255)},0)`,
            `rgb(${Math.round(props.stats.percent.coral2Prob>.5?props.stats.percent.coral2Prob*255*2:255)},${Math.round(props.stats.percent.coral1Prob<.5?props.stats.percent.coral2Prob*255*2:255)},0)`,
            `rgb(${Math.round(props.stats.percent.coral3Prob>.5?props.stats.percent.coral3Prob*255*2:255)},${Math.round(props.stats.percent.coral1Prob<.5?props.stats.percent.coral3Prob*255*2:255)},0)`,
            `rgb(${Math.round(props.stats.percent.coral4Prob>.5?props.stats.percent.coral4Prob*255*2:255)},${Math.round(props.stats.percent.coral1Prob<.5?props.stats.percent.coral4Prob*255*2:255)},0)`,
            ]);
            // for (let i=0.00; i < 1; i+= 0.05) {
            //     console.log(`${i} : rgb(${Math.round(i>.5?i*255*2:255)},${Math.round(i<.5?i*255*2:255)},0)`)
            // }
            // console.log(props.stats.percent.coral1Prob);
            // console.log(props.stats.percent.coral2Prob);
            // console.log(props.stats.percent.coral3Prob);
            // console.log(props.stats.percent.coral4Prob);
        }
    }, [props]);

    return (
        <Canvas shadows>
            <ambientLight intensity={1.7} />
            <spotLight
                position={[0, 170, 0]}
                intensity={80000}
                castShadow
            />

            <OrbitControls
                minDistance={150}
                maxDistance={270}
            />
            <STLModel url={ReefBase} position={[0, offsetfromcenterbv, 1 * offsetfromcenterb]} rotation={[3* Math.PI / 2 , 0 , 0]} color = {"gray"} />
            <STLModel url={ReefBase} position={[0, offsetfromcenterbv, -1 * offsetfromcenterb]} rotation={[3* Math.PI / 2 , 0 , Math.PI]} color = {"gray"} />
            <STLModel url={ReefBase} position={[Math.sin(Math.PI/3) * offsetfromcenterb, offsetfromcenterbv, Math.cos(Math.PI/3) * offsetfromcenterb]} rotation={[3* Math.PI / 2 , 0 , Math.PI/3]} color = {"gray"} />
            <STLModel url={ReefBase} position={[Math.sin(2*Math.PI/3) * offsetfromcenterb, offsetfromcenterbv, Math.cos(2*Math.PI/3) * offsetfromcenterb]} rotation={[3* Math.PI / 2 , 0 , 2*Math.PI/3]} color = {"gray"} />
            <STLModel url={ReefBase} position={[Math.sin(Math.PI/3) * offsetfromcenterb * -1, offsetfromcenterbv, Math.cos(Math.PI/3) * offsetfromcenterb * -1]} rotation={[3* Math.PI / 2 , 0 , 4*Math.PI/3]} color = {"gray"} />
            <STLModel url={ReefBase} position={[Math.sin(2*Math.PI/3) * offsetfromcenterb * -1, offsetfromcenterbv, Math.cos(2*Math.PI/3) * offsetfromcenterb* -1]} rotation={[3* Math.PI / 2 , 0 , 5*Math.PI/3]} color = {"gray"} />

            {/* vertical ones */}
            <STLModel url={ReefTree} position={[Math.sin(3.33*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.cos(3.12*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 3*Math.PI/2]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.sin(3.27*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.cos(3.12*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 3*Math.PI/2]} color = {"purple"} />
            <STLModel url={ReefTree} position={[Math.sin(3.27*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.cos(3.12*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , Math.PI/2]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.sin(3.33*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.cos(3.12*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , Math.PI/2]} color = {"purple"} />

            {/* angled ones */}
            <STLModel url={ReefTree} position={[Math.cos(3.19*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.sin(3.09*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 5*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[Math.cos(3.81*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.sin(3.68*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 5*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.cos(3*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.sin(3.05*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 1*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.cos(3.79*Math.PI/3) * offsetfromcentert, offsetfromcentertv, Math.sin(3.617*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 1*Math.PI/6]} color = {"purple"} />

            <STLModel url={ReefTree} position={[Math.cos(3.19*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.sin(3.05*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 7*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[Math.cos(3.81*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.sin(3.617*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , 7*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.cos(3*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.sin(3.09*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , -1*Math.PI/6]} color = {"purple"} />
            <STLModel url={ReefTree} position={[-Math.cos(3.79*Math.PI/3) * offsetfromcentert, offsetfromcentertv, -Math.sin(3.68*Math.PI/2.87) * offsetfromcentert]} rotation={[3* Math.PI / 2 , 0 , -1*Math.PI/6]} color = {"purple"} />

            {/* Ground Plane */}
            <mesh position={[0, -75, 0]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
            <planeGeometry args={[100000, 100000]} />
            <meshStandardMaterial color="lightgray" />
            </mesh>

            {/* Different Level Heatmaps */}

            <mesh position={[0,-22,0]} rotation={[0, Math.PI / 6, 0]}>
                <cylinderGeometry args={[80, 90, 12, 6]}/>
                <meshStandardMaterial
                    color={colors[0]}
                    transparent={true}
                    opacity={0.3}
                    />
            </mesh>

            <UpperLevelCylinder color={colors[1]} position={[0,-5,0]} rotation={[0, Math.PI / 6, 0]}/>

            <UpperLevelCylinder color={colors[2]} position={[0,35,0]} rotation={[0, Math.PI / 6, 0]}/>

            <UpperLevelCylinder color={colors[3]} position={[0,80,0]} rotation={[0, Math.PI / 6, 0]}/>
        </Canvas>
      );
}

export default Reef;