import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PredictionCard = (props) => {
    const [teams, setTeams] = useState();
    const [keys, setKeys] = useState();

    const { number } = useParams();


    useEffect(() => {
        if (props.alliance && keys) {
            let correctedTeams = []
            
            Object.keys(props.alliance).map((i)=> {
                
                console.log(props.alliance[i])
                correctedTeams.push({"key": keys[i].slice(3), "match": (props.alliance[i].qm || {})[number]})
            })
            setTeams(correctedTeams);
            console.log(correctedTeams);
        }
        if (props.keys) {
            setKeys(props.keys);
        }
    }, [props])

    if (teams) {
        return ( 
            <DataTable 
                withBorder
                withColumnBorders
                striped
                highlightOnHover
                borderColor={(props.color === "red" ? props.color : props.color)}
                records={[
                    {
                        column1: ("Autos Coral 1"),
                        column2: (`${teams[0].match.autoCoral1?teams[0].match.autoCoral1:"N/A"} | ${Math.round(teams[0].match.autoCoral1Prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral1?teams[1].match.autoCoral1:"N/A"} | ${Math.round(teams[1].match.autoCoral1Prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral1?teams[2].match.autoCoral1:"N/A"} | ${Math.round(teams[2].match.autoCoral1Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Autos Coral 2"),
                        column2: (`${teams[0].match.autoCoral2?teams[0].match.autoCoral2:"N/A"} | ${Math.round(teams[0].match.autoCoral2Prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral2?teams[1].match.autoCoral2:"N/A"} | ${Math.round(teams[1].match.autoCoral2Prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral2?teams[2].match.autoCoral2:"N/A"} | ${Math.round(teams[2].match.autoCoral2Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Autos Coral 3"),
                        column2: (`${teams[0].match.autoCoral3?teams[0].match.autoCoral3:"N/A"} | ${Math.round(teams[0].match.autoCoral3Prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral3?teams[1].match.autoCoral3:"N/A"} | ${Math.round(teams[1].match.autoCoral3Prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral3?teams[2].match.autoCoral3:"N/A"} | ${Math.round(teams[2].match.autoCoral3Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Autos Coral 4"),
                        column2: (`${teams[0].match.autoCoral4?teams[0].match.autoCoral4:"N/A"} | ${Math.round(teams[0].match.autoCoral4Prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral4?teams[1].match.autoCoral4:"N/A"} | ${Math.round(teams[1].match.autoCoral4Prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral4?teams[2].match.autoCoral4:"N/A"} | ${Math.round(teams[2].match.autoCoral4Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Autos Points"),
                        column2: (teams[0].match.autoPoints),
                        column3: (teams[1].match.autoPoints),
                        column4: (teams[2].match.autoPoints),
                        
                    },
                    {
                        column1: ("Autos Net Accuracy"),
                        column2: (teams[0].match.autoRobotNetAcc),
                        column3: (teams[1].match.autoRobotNetAcc),
                        column4: (teams[2].match.autoRobotNetAcc),
                    },
                    {
                        column1: ("Teleop Coral 1"),
                        column2: (`${teams[0].match.teleopCoral1?teams[0].match.teleopCoral1:"N/A"} | ${Math.round(teams[0].match.teleopCoral1Prob*10000)/100}%`),
                        column3: (`${teams[1].match.teleopCoral1?teams[1].match.teleopCoral1:"N/A"} | ${Math.round(teams[1].match.teleopCoral1Prob*10000)/100}%`),
                        column4: (`${teams[2].match.teleopCoral1?teams[2].match.teleopCoral1:"N/A"} | ${Math.round(teams[2].match.teleopCoral1Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Teleop Coral 2"),
                        column2: (`${teams[0].match.teleopCoral2?teams[0].match.teleopCoral2:"N/A"} | ${Math.round(teams[0].match.teleopCoral2Prob*10000)/100}%`),
                        column3: (`${teams[1].match.teleopCoral2?teams[1].match.teleopCoral2:"N/A"} | ${Math.round(teams[1].match.teleopCoral2Prob*10000)/100}%`),
                        column4: (`${teams[2].match.teleopCoral2?teams[2].match.teleopCoral2:"N/A"} | ${Math.round(teams[2].match.teleopCoral2Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Teleop Coral 3"),
                        column2: (`${teams[0].match.teleopCoral3?teams[0].match.teleopCoral3:"N/A"} | ${Math.round(teams[0].match.teleopCoral3Prob*10000)/100}%`),
                        column3: (`${teams[1].match.teleopCoral3?teams[1].match.teleopCoral3:"N/A"} | ${Math.round(teams[1].match.teleopCoral3Prob*10000)/100}%`),
                        column4: (`${teams[2].match.teleopCoral3?teams[2].match.teleopCoral3:"N/A"} | ${Math.round(teams[2].match.teleopCoral3Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Teleop Coral 4"),
                        column2: (`${teams[0].match.teleopCoral4?teams[0].match.teleopCoral4:"N/A"} | ${Math.round(teams[0].match.teleopCoral4Prob*10000)/100}%`),
                        column3: (`${teams[1].match.teleopCoral4?teams[1].match.teleopCoral4:"N/A"} | ${Math.round(teams[1].match.teleopCoral4Prob*10000)/100}%`),
                        column4: (`${teams[2].match.teleopCoral4?teams[2].match.teleopCoral4:"N/A"} | ${Math.round(teams[2].match.teleopCoral4Prob*10000)/100}%`),
                        
                    },
                    {
                        column1: ("Teleop Points"),
                        column2: (teams[0].match.teleopPoints),
                        column3: (teams[1].match.teleopPoints),
                        column4: (teams[2].match.teleopPoints),
                        
                    },
                    {
                        column1: ("Average Algae/min"),
                        column2: (teams[0].match.avgAlgaePerMin),
                        column3: (teams[1].match.avgAlgaePerMin),
                        column4: (teams[2].match.avgAlgaePerMin),
                    },
                    {
                        column1: ("Average Coral/min"),
                        column2: (teams[0].match.avgCoralPerMin),
                        column3: (teams[1].match.avgCoralPerMin),
                        column4: (teams[2].match.avgCoralPerMin),
                    },
                    {
                        column1: ("Ground Intake"),
                        column2: (`${teams[0].match.groundIntakes?teams[0].match.groundIntakes:"N/A"} | ${teams[0].match.coralIntakeGroundProb}%`),
                        column3: (`${teams[1].match.groundIntakes?teams[1].match.groundIntakes:"N/A"} | ${teams[1].match.coralIntakeGroundProb}%`),
                        column4: (`${teams[2].match.groundIntakes?teams[2].match.groundIntakes:"N/A"} | ${teams[2].match.coralIntakeGroundProb}%`),
                    },
                    {
                        column1: ("Sub Intake"),
                        column2: (`${teams[0].match.substationIntakes?teams[0].match.substationIntakes:"N/A"} | ${teams[0].match.coralIntakeSubstationProb}%`),
                        column3: (`${teams[1].match.substationIntakes?teams[1].match.substationIntakes:"N/A"} | ${teams[1].match.coralIntakeSubstationProb}%`),
                        column4: (`${teams[2].match.substationIntakes?teams[2].match.substationIntakes:"N/A"} | ${teams[2].match.coralIntakeSubstationProb}%`),
                    },
                    {
                        column1: ("Algae Take High"),
                        column2: (teams[0].match.teleopAlgaeHighProb * 100).toFixed(2) + "%",
                        column3: (teams[1].match.teleopAlgaeHighProb * 100).toFixed(2) + "%",
                        column4: (teams[2].match.teleopAlgaeHighProb * 100).toFixed(2) + "%",
                    },
                    {
                        column1: ("Algae Take Low"),
                        column2: (teams[0].match.teleopAlgaeLowProb * 100).toFixed(2) + "%",
                        column3: (teams[1].match.teleopAlgaeLowProb * 100).toFixed(2) + "%",
                        column4: (teams[2].match.teleopAlgaeLowProb * 100).toFixed(2) + "%",
                    },
                    {
                        column1: ("Teleop Net Accuracy"),
                        column2: (teams[0].match.teleopAlgaeRobotNet / (teams[0].match.teleopAlgaeRobotNet + teams[0].match.teleopAlgaeRobotNetMiss)),
                        column3: (teams[1].match.teleopAlgaeRobotNet / (teams[1].match.teleopAlgaeRobotNet + teams[1].match.teleopAlgaeRobotNetMiss)),
                        column4: (teams[2].match.teleopAlgaeRobotNet / (teams[2].match.teleopAlgaeRobotNet + teams[2].match.teleopAlgaeRobotNetMiss)),
                    },
                    {
                        column1: ("Human Net Accuracy"),
                        column2: (teams[0].match.teleopAlgaeHumanNet / (teams[0].match.teleopAlgaeHumanNet + teams[0].match.teleopAlgaeHumanNetMiss)),
                        column3: (teams[1].match.teleopAlgaeHumanNet / (teams[1].match.teleopAlgaeHumanNet + teams[1].match.teleopAlgaeHumanNetMiss)),
                        column4: (teams[2].match.teleopAlgaeHumanNet / (teams[2].match.teleopAlgaeHumanNet + teams[2].match.teleopAlgaeHumanNetMiss)),
                    },
                    {
                        column1: ("Processor"),
                        column2: (teams[0].match.teleopAlgaeProcessor),
                        column3: (teams[1].match.teleopAlgaeProcessor),
                        column4: (teams[2].match.teleopAlgaeProcessor),
                    },
                    {
                        column1: ("Total Coral 1"),
                        column2: (`${teams[0].match.autoCoral1?teams[0].match.teleopCoral1?(teams[0].match.autoCoral1+teams[0].match.teleopCoral1):"N/A":"N/A"} | ${Math.round(teams[0].match.totalCoral1prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral1?teams[1].match.teleopCoral1?(teams[1].match.autoCoral1+teams[1].match.teleopCoral1):"N/A":"N/A"} | ${Math.round(teams[1].match.totalCoral1prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral1?teams[2].match.teleopCoral1?(teams[2].match.autoCoral1+teams[2].match.teleopCoral1):"N/A":"N/A"} | ${Math.round(teams[2].match.totalCoral1prob*10000)/100}%`),
                    },
                    {
                        column1: ("Total Coral 2"),
                        column2: (`${teams[0].match.autoCoral2?teams[0].match.teleopCoral2?(teams[0].match.autoCoral2+teams[0].match.teleopCoral2):"N/A":"N/A"} | ${Math.round(teams[0].match.totalCoral2prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral2?teams[1].match.teleopCoral2?(teams[1].match.autoCoral2+teams[1].match.teleopCoral2):"N/A":"N/A"} | ${Math.round(teams[1].match.totalCoral2prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral2?teams[2].match.teleopCoral2?(teams[2].match.autoCoral2+teams[2].match.teleopCoral2):"N/A":"N/A"} | ${Math.round(teams[2].match.totalCoral2prob*10000)/100}%`),
                    },
                    {
                        column1: ("Total Coral 3"),
                        column1: ("Total Coral 2"),
                        column2: (`${teams[0].match.autoCoral3?teams[0].match.teleopCoral3?(teams[0].match.autoCoral3+teams[0].match.teleopCoral3):"N/A":"N/A"} | ${Math.round(teams[0].match.totalCoral3prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral3?teams[1].match.teleopCoral3?(teams[1].match.autoCoral3+teams[1].match.teleopCoral3):"N/A":"N/A"} | ${Math.round(teams[1].match.totalCoral3prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral3?teams[2].match.teleopCoral3?(teams[2].match.autoCoral3+teams[2].match.teleopCoral3):"N/A":"N/A"} | ${Math.round(teams[2].match.totalCoral3prob*10000)/100}%`),
                    },
                    {
                        column1: ("Total Coral 4"),
                        column1: ("Total Coral 2"),
                        column2: (`${teams[0].match.autoCoral4?teams[0].match.teleopCoral4?(teams[0].match.autoCoral4+teams[0].match.teleopCoral4):"N/A":"N/A"} | ${Math.round(teams[0].match.totalCoral4prob*10000)/100}%`),
                        column3: (`${teams[1].match.autoCoral4?teams[1].match.teleopCoral4?(teams[1].match.autoCoral4+teams[1].match.teleopCoral4):"N/A":"N/A"} | ${Math.round(teams[1].match.totalCoral4prob*10000)/100}%`),
                        column4: (`${teams[2].match.autoCoral4?teams[2].match.teleopCoral4?(teams[2].match.autoCoral4+teams[2].match.teleopCoral4):"N/A":"N/A"} | ${Math.round(teams[2].match.totalCoral$prob*10000)/100}%`),
                    },
                    {
                        column1: ("Total Points"),
                        column2: (teams[0].match.totalPoints),
                        column3: (teams[1].match.totalPoints),
                        column4: (teams[2].match.totalPoints),
                        
                    },
                    {
                        column1: ("Total Coral"),
                        column2: (teams[0].match.totalCoralScored),
                        column3: (teams[1].match.totalCoralScored),
                        column4: (teams[2].match.totalCoralScored),
                    },
                    {
                        column1: ("Climb Status"),
                        column2: (teams[0].match.climbStatus),
                        column3: (teams[1].match.climbStatus),
                        column4: (teams[2].match.climbStatus),
                    },
                    {
                        column1: ("Died"),
                        column2: (teams[0].match.died?"Buddy Died" : "he livin"),
                        column3: (teams[1].match.died?"Buddy Died" : "he livin"),
                        column4: (teams[2].match.died?"Buddy Died" : "he livin"),
                    }
                ]}
                columns={[
                    { accessor: 'column1', title: "Data", width: 20, textAlignment: 'center', ellipsis: true},
                    { accessor: 'column2', title: String(teams[0].key), width: 15, textAlignment: 'center'},
                    { accessor: 'column3', title: String(teams[1].key), width: 15, textAlignment: 'center'},
                    { accessor: 'column4', title: String(teams[2].key), width: 15, textAlignment: 'center'}, 
                    // Replace with keys String(teams[0].team) -> keys
                ]}
            />
                        
        );
    }
    
}

export default PredictionCard;
