import React, { useState, useEffect } from "react"; 
import { Card, Grid, Title, Space, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import PredictionCard from "../PredictionCard";

const Match = (props) => {
    const { number } = useParams();
    const [loading, setLoading] = useState();
    const [red, setRed] = useState(); // list of red alliance teams
    const [blue, setBlue] = useState(); // list of blue alliance teams
    const [redStats, setRedStats] = useState();
    const [blueStats, setBlueStats] = useState();


    useEffect(() => {
        setLoading(true);
    }, [props.event, number]);

    useEffect(() => {
        const getTeams = async () => {
            await fetch(`https://www.thebluealliance.com/api/v3/match/${props.event}_qm${number}/simple`, { headers: { "X-TBA-Auth-Key": "fLKsnM61nLrIA7CDpmFybAXvRYFeCQbIRrPYm5sKSSrs77vsUhL2whnLIHizc3iU" }})
                .then((response) => response.json())
                .then((data) => {
                    setRed(data.alliances.red.team_keys);
                    setBlue(data.alliances.blue.team_keys);
                });
        }
        getTeams();
    }, [loading]);

    useEffect(() => {
        const asyncFunction = async () => {
            const setTeams = async (teams) => {
                let array = [];
                for (var i in teams) {
                    await fetch(`https://server.palyrobotics.com/team/${props.event}/${teams[i].replace(/frc/g, '')}/${number}`)
                        .then((response) => response.json())
                        .then((data) => {
                            array.push(Object.assign({}, data));
                        });
                }
                return array;
            }

            const setStats = async (teams) => {
                let array = [];
                for (var i in teams) {
                    await fetch(`https://server.palyrobotics.com/team/${props.event}/${teams[i].replace(/frc/g, '')}`)
                        .then((response) => response.json())
                        .then((data) => {
                            array.push(Object.assign({}, data));
                        })
                }
                return array;
            }

            if (blue) {
                let redArray = await setTeams(red);
                let blueArray = await setTeams(blue);

                let redStats = await setStats(red);
                let blueStats = await setStats(blue);

                setRedStats(redStats);
                setBlueStats(blueStats);

                setLoading(false);
            } 
        }
        asyncFunction();
        
    }, [blue])
    
    if (loading) return (
        <div className="container">Loading...</div>
    );
    

        return (
            <Grid>
                <Grid.Col span={12} >
                    <Card>
                        <Title order={3}>QM {number}</Title>
                        <Space h="md" />
                        <Grid>
                            <Grid.Col span={6}>
                                <PredictionCard keys={red} alliance={redStats} color={"red"}/>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <PredictionCard keys={blue} alliance={blueStats} color={"blue"}/>
                            </Grid.Col>
                        </Grid>
                    </Card>
                </Grid.Col>
            </Grid>
        );
    // }
}

export default Match;
